/* @tailwind base;
@tailwind components;
@tailwind utilities; */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-container {
  display: grid;
  grid-template-columns: 0.75fr 2.5fr 1.25fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . .";
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
}

.navfont {
  font-family: "Raleway";
}
